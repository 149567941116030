// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/tis.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inputContainer[data-v-5e06471d]{display:-webkit-box;display:-webkit-flex;display:-moz-box;display:flex;-webkit-box-align:center;-webkit-align-items:center;-moz-box-align:center;align-items:center}.inputContainer .card[data-v-5e06471d]{margin-left:-2px;-webkit-flex-shrink:0;flex-shrink:0;width:33px;height:33px;background:#f4f4f4;border-radius:2px;border:1px solid #e1e3e6;line-height:33px;text-align:center;font-weight:400;font-size:14px;color:#333}.description[data-v-5e06471d]{display:-webkit-box;display:-webkit-flex;display:-moz-box;display:flex;background:#e8f0ff;border-radius:4px;padding:9px 10px}.description .bgi[data-v-5e06471d]{margin-top:3px;width:12px;height:12px;-webkit-flex-shrink:0;flex-shrink:0;margin-right:8px;background-size:100% 100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.description .desc[data-v-5e06471d]{-webkit-box-flex:1;-webkit-flex:1;-moz-box-flex:1;flex:1;font-family:Microsoft YaHei;font-weight:400;font-size:12px;color:#333;line-height:20px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
